import {useEffect, useRef, useState} from "react";
import {LoadingSpinner} from "./loading/LoadingSpinner";
import Rosetta from "../rosetta/Rosetta";

export const RosettaAwareComponent = (props) => {

    const {loadingComponent} = props;
    const {callback} = props;

    const [rosettaReady, setRosettaReady] = useState(false);
    const [timerMutation, setTimerMutation] = useState(0);
    const [hasCleared, setHasCleared] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const readyTimer = useRef();

    useEffect(() => {
        const rosetta = Rosetta.getInstance();

        rosetta.addCallback((state) => {
            setRosettaReady(state === Rosetta.STATE_READY);

            if (state === Rosetta.STATE_ERROR) {
                setErrorMessage("ERROR: " + Rosetta.getInstance().getError());
            } else {
                setErrorMessage(null);
            }
        })

        if (Rosetta.getInstance().getState() === Rosetta.STATE_ERROR) {
            setErrorMessage(Rosetta.getInstance().getError());
        }

        if (!rosettaReady) {
            console.log("Starting ready timer...");
            // Have a timer running, checking to see if Rosetta is ready.
            readyTimer.current = setInterval(() => {
                console.log("Ready timer firing...");
                setTimerMutation(Math.random());
            }, 200);
        }

        return () => {
            clearInterval(readyTimer.current);
        }
    }, []);

    useEffect(() => {
        if (rosettaReady) {
            if (callback) {
                callback();
            }

            clearInterval(readyTimer.current);
        }
    }, [rosettaReady]);

    useEffect(() => {
        console.log("RAC Timer mutation triggered!");
        if (rosettaReady || Rosetta.getInstance().getState() === Rosetta.STATE_READY) {
            console.log("RAC Clear timer.");
            clearInterval(readyTimer.current);
            if (!hasCleared) {
                setTimerMutation(Math.random());
            }
        }
    }, [timerMutation])

    // RENDER

    console.log("RAC: Render()");

    let immediatelyReady = Rosetta.getInstance().getState() === Rosetta.STATE_READY;
    let immediateError = errorMessage;
    if (Rosetta.getInstance().getState() === Rosetta.STATE_ERROR) {
        immediateError = Rosetta.getInstance().getError();
        if (!immediateError) {
            immediateError = "Unknown Rosetta error! [RSL2000C]";
        }
    }

    if (!rosettaReady && !immediatelyReady) {
        console.log("RAC Render: Rosetta not ready.");

        let loadingUI = [<LoadingSpinner />];
        if (loadingComponent !== undefined) {
            loadingUI = [loadingComponent];
        }

        if (immediateError !== null) {
            loadingUI.push(
                <div className={"row mt-4"}>
                    <div className={"col-12 text-center"}>
                        <div>An error has been encountered.</div>
                        <h4>{errorMessage}</h4>

                        <button className={"btn btn-primary"} onClick={() => window.location.reload()}>
                            Try again
                        </button>
                    </div>
                </div>
            );
        } else {
            loadingUI.push(
                <div className={"row"}>
                    <div className={"col-12 text-center"}>
                        Initialising, please wait...
                    </div>
                </div>
            )
        }

        return loadingUI;
    }

    if (!hasCleared) {
        setHasCleared(true);
    }

    return props.children;

}